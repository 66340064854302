import { trigger, transition, animate, style } from '@angular/animations';

export const LongShow = trigger('LongShow', [
    transition(':enter', [
        style({
            opacity: 0,
        }),
        animate(
            '1s cubic-bezier(0.25, 0, 0.1, 1)',
            style({
                opacity: 1,
            }),
        ),
    ]),
]);
